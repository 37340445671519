import React from 'react';
import './TrademarkComponent.css'; // Assuming you have a CSS file for styling

const Trademark = () => {
  return (
    <div className="trademark">
      © Carbon13 2024
    </div>
  );
};

export default Trademark;