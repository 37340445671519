import './HeroSectionComponent.css'; // Ensure you have this CSS file
import React, { useState, useEffect } from 'react';

import mainVideo from './assets/moving-river.mp4';
const HeroSectionComponent = () => {

    const fullText = '      Let\'s build a more sustainable world.';
    const [typedText, setTypedText] = useState('');
    const typingDelay = 110; // Milliseconds
  
    useEffect(() => {
      if (typedText.length < fullText.length) {
        setTimeout(() => {
          setTypedText(fullText.substring(0, typedText.length + 1));
        }, typingDelay);
      }
    }, [typedText, fullText]);  

  return (    
    <div className="hero-section">   
      <video
        id="hero-video"
        className="video-div"
        src={mainVideo}
        data-type="mp4"
        loop
        muted
        playsInline
        autoPlay
      ></video>
      <div className="hero-text">
        <h1>CARBON<span className="golden-orange">13</span></h1>
        <h2 >{typedText}</h2>
      </div>
    </div>
  );
};

export default HeroSectionComponent;