import React from 'react';
import './ProcessComponent.css';
import excellenceIcon from './assets/excellence.png';
import clarityIcon from './assets/research.png';
import adaptabilityIcon from './assets/adaptation.png';
import transparencyIcon from './assets/shared-vision.png';

import analyseIcon from './assets/analysing.png';
import strategiseIcon from './assets/target.png';
import implementIcon from './assets/implementation.png';
import deliverIcon from './assets/delivered.png';


const ethosDetails = [
    {
      title: "Excellence",
      description: "We aim to be the best at what we do and are profoundly mission driven. Our work is not over if the client is not fully satisfied."
    },
    {
        title: "Clarity",
        description: "We simplify complex processes, keep communication clear and frequent, are responsive and open about our processes."
    },
    {
        title: "Adaptability",
        description: "We adapt to your specific needs and operational constraints, however difficult or specific these are, and regardless of your location."
    },
    {
        title: "Transparency",
        description: "We provide clear insights and thoroughly detailed documentation about the work we do and how it positively impacts the environment."
    }
];

const actionDetails = [
    {
        title: "Analyse",
        description: "We provide a thorough analysis of clients needs and research opportunities to develop new revenue streams for our clients while adopting more sustainable business practices."
    },
    {
        title: "Strategise",
        description: "We are constantly on top of new market data and regulations, and come up with a detailed strategy to achieve the goals our clients desire. We plan out operations to deliver in a structured and timely way."
    },
    {
      title: "Implement",
      description: "We use our network of experts and our scientific expertise to implement the tools and protocols that our clients need to achieve their goals, from end to end."
    },
    {
        title: "Deliver",
        description: "We take pride in achieving and exceeding the goals set out by our clients. We continuously improve, revise and address our solution if changes are necessary, even after final delivery."
    }
];

const shadesOfGreen = ['#E0F2F1', '#B2DFDB', '#80CBC4', '#4DB6AC']; 
const shadesOfAmber = ['#FFD54F','#FFE082','#FFECB3','#FFF8E1'];
const processIcons = [excellenceIcon, clarityIcon, adaptabilityIcon, transparencyIcon].reverse();
const actionIcons = [analyseIcon, strategiseIcon, implementIcon, deliverIcon].reverse();

const ProcessComponent = () => {
  return (
      <div className="process-section">
          <h2>Our Ethos</h2>
          <div className="process-details">
              {ethosDetails.map((detail, index) => (
                  <div className="card" key={index} style={{ backgroundColor: shadesOfGreen[ethosDetails.length - 1 - index] }}>
                      <img src={processIcons[ethosDetails.length - 1 - index]} alt={detail.title} />
                      <h3>{detail.title}</h3>
                      <p>{detail.description}</p>
                  </div>
              ))}
          </div>
          <h2>Our Approach</h2>
          <div className="action-details">
              {actionDetails.map((detail, index) => (
                  <div className="card" key={index} style={{ backgroundColor: shadesOfAmber[actionDetails.length - 1 - index] }}>
                      <img src={actionIcons[actionDetails.length - 1 - index]} alt={detail.title} />
                      <h3>{detail.title}</h3>
                      <p>{detail.description}</p>
                  </div>
              ))}
          </div>
      </div>
  );
};

export default ProcessComponent;
