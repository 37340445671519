import React from 'react';
import HeaderComponent from './HeaderComponent';
import HeroSectionComponent from './HeroSectionComponent';
import ServicesComponent from './ServicesComponent';
import VisionComponent from './VisionComponent';
import ProcessComponent from './ProcessComponent';
import PlanSection from './PlanSection';
import CertificationsComponent from './CertificationsComponent';
import Trademark from './TrademarkComponent';
import './App.css'; 
import DeveloperServicesComponent from './DeveloperServicesComponent';
import BusinessServicesComponent from './BusinessServicesComponent';
import ContactFormComponent from './ContactFormComponent';

function App() {
  const sectionStyle = { marginBottom: '33px' }; // Easily modifiable spacing variable

  return (
    <div className="App">
     <HeaderComponent />
      <div style={sectionStyle}><HeroSectionComponent /></div>
      <div style={sectionStyle}><ServicesComponent /></div>
      <div style={sectionStyle}><DeveloperServicesComponent /></div>
      <div style={sectionStyle}><BusinessServicesComponent /></div>
      <div style={sectionStyle}><VisionComponent /></div>
      <div style={sectionStyle}><ProcessComponent /></div>
      <div style={sectionStyle}><PlanSection /></div>
      <div style={sectionStyle}><CertificationsComponent /></div>
      <div style={sectionStyle}><ContactFormComponent /></div>
      <footer>
        <Trademark />
      </footer>
    </div>
  );
}

export default App;
