import React, { useState, useEffect, useRef } from 'react';
import './PlanSection.css'; // Ensure this CSS file exists and contains the necessary styles
import video from './assets/moving-grass-blades.mp4';
const lines = [
  "We Analyse.",
  "We Strategise.",
  "We Implement.",
  "We Deliver."
];

const PlanSection = () => {
  const [currentLine, setCurrentLine] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const typingDelay = 90;
  const nextLineDelay = 100;
  const sectionRef = useRef(null); // Ref for the plan section
  const videoRef = useRef(null); // Ref for the video

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    }, { threshold: 0.1 }); // Adjust threshold as needed

    observer.observe(sectionRef.current);

    return () => observer.disconnect(); // Clean up observer
  }, []);

  useEffect(() => {
    let timeoutId = null;

    if (isVisible && currentLine < lines.length) {
      if (typedText !== lines[currentLine]) {
        timeoutId = setTimeout(() => {
          setTypedText(lines[currentLine].substring(0, typedText.length + 1));
        }, typingDelay);
      } else {
        timeoutId = setTimeout(() => {
          setCurrentLine(currentLine => currentLine + 1);
          setTypedText('');
        }, nextLineDelay);
      }
    } else if (!isVisible) {
      setCurrentLine(0);
      setTypedText('');
    }

    return () => clearTimeout(timeoutId);
  }, [isVisible, typedText, currentLine]);

  const renderLine = (line, isCurrent) => {
    const words = line.split(" ");
    if (words.length > 1 && isCurrent) {
      return (
        <>
          {words[0]}{" "}
          <span className="golden-orange">{words[1]}</span>{" "}
          {words.slice(2).join(" ")}
        </>
      );
    }
    return line;
  };

  return (
    <div className="plan-section" ref={sectionRef}>
      <video
        ref={videoRef}
        className="background-video-3"
        src={video}
        loop
        muted
        playsInline
        autoPlay
      ></video>
      <div className="plan-text">
        {lines.slice(0, currentLine + 1).map((line, index) => (
          <h1 key={index}>{index === currentLine ? renderLine(typedText, true) : renderLine(line, false)}</h1>
        ))}
      </div>
    </div>
  );
};

export default PlanSection;
