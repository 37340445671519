import React from 'react';
import './BusinessServicesComponent.css';
import connectionIcon from './assets/connection.png'; // Assume this is the path to your icon
import co2Icon from './assets/co2-emission.png';
import accountingIcon from './assets/budget.png';
import monitorIcon from './assets/monitor.png';

const BusinessServicesComponent = () => {
  return (
    <div className="business-services">
      <h2>For businesses</h2>
         <p>  Regulations are evolving fast to counter climate change and we want to put our clients on the top of tomorrow's new regulatory frameworks, 
            by helping them track and quantify their greenhouse gas emissions, and by providing reduction plans with intelligent timelines and minimal disruption to their operations. 
            We connect our clients with opportunities to offset their emissions efficiently and durably. </p>
<div className="service-row-business">
        <div className="content">
        <img src={accountingIcon} alt="Appraisal" />
          <div className="title"><strong>Accounting</strong></div>
          <p className="description">
            We use our proprietary models to compute and monitor our client's greenhouse gas emissions, 
            with quantified uncertainty. Using minimal data ensuring the privacy of your business isn't compromised,
            We compute emissions from scope 1, 2 and 3 as outlined by the UN GHG Protocol.
          </p></div>
      </div>
      <div className="service-row-business">
        <div className="content">
        <img src={monitorIcon} alt="Analysis" />
          <div className="title"><strong>Analysis</strong></div>
          <p className="description">
            We analyse your greenhouse gas emissions and provide deep insights into the issues 
            and bottlenecks causing the most emissions. 
            We provide clients with comprehensive solutions and plans 
            to reduce their emissions and overcome these obstacles in a sustainable and non-invasive way.  
          </p></div>
      </div>
      <div className="service-row-business">
        <div className="content">
        <img src={co2Icon} alt="Offsetting" />
          <div className="title"><strong>Offsetting</strong></div>
          <p className="description">
            We offer a range of carbon offsetting products, such as carbon credits of various prices and qualities and advise our clients on which standards
            are most suitable to their needs. We also provide more sophisticated investment opportunities in the form of 
            carbon credit derivatives, which our clients can integrate in long-term carbon neutrality strategies. 
          </p></div>
      </div>
      <div className="service-row-business">
        <div className="content">
        <img src={connectionIcon} alt="CreditTrading" />
          <div className="title"><strong>Connecting</strong></div>
          <p className="description">
            We connect our clients to sustainable project developers and initiatives,
            as well as with partners that are low-emission alternatives to their current suppliers, 
            with a possibility to bridge the price gap if any, in an effort to build a virtuous ecosystem of sustainable businesses.
          </p>        </div>
      </div>
    </div>
  );
};

export default BusinessServicesComponent;
