import React, { useState, useEffect, useRef } from 'react';
import './VisionComponent.css';
import backgroundVideo from './assets/trees-and-windmills.mp4';

const lines = ["We are Mission Driven."];

const VisionComponent = () => {
  const [currentLine, setCurrentLine] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const typingDelay = 90;
  const nextLineDelay = 200;
  const sectionRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      });
    }, { threshold: 0.1 });

    observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, [sectionRef]);

  useEffect(() => {
    let timeoutId = null;

    if (isVisible && currentLine < lines.length) {
      if (typedText !== lines[currentLine]) {
        timeoutId = setTimeout(() => {
          setTypedText(t => lines[currentLine].substring(0, t.length + 1));
        }, typingDelay);
      } else {
        timeoutId = setTimeout(() => {
          setCurrentLine(c => c + 1);
          setTypedText('');
        }, nextLineDelay);
      }
    } else if (!isVisible) {
      setCurrentLine(0);
      setTypedText('');
    }

    return () => clearTimeout(timeoutId);
  }, [isVisible, typedText, currentLine]);

  const renderLine = (line, isCurrent) => {
    const words = line.split(" ");
    if (words.length > 1 && isCurrent) {
      return (
        <>
          {words.slice(0, -1).join(" ")}{" "}
          <span className="golden-orange">{words[words.length - 1]}</span>
        </>
      );
    }
    return line;
  };

  return (
    <div className="hero-container" ref={sectionRef}>
      <video ref={videoRef} loop muted className='video-div-2'>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-text">
        {lines.slice(0, currentLine + 1).map((line, index) => (
          <h1 key={index}>{index === currentLine ? renderLine(typedText, true) : renderLine(line, false)}</h1>
        ))}
      </div>
    </div>
  );
};

export default VisionComponent;
