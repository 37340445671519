import React, { useState, useEffect } from 'react';
import puroearth from './assets/puro.earth.png';
import './CertificationsComponent.css';
import goldstd from "./assets/LOGO_GOLD.avif";
import vcs from "./assets/LOGO_VCS.avif";
import ghg from "./assets/LOGO_GHG.jpg";
import un from './assets/LOGO_UN.avif';
import woodland from "./assets/LOGO_WOODLAND.avif";


const logos = [
    { src: ghg, alt: "GHG Protocol" },
    { src: puroearth, alt: "Puro Earth" },
    { src: goldstd, alt: "Gold Standard" },
    { src: vcs, alt: "Verified Carbon Standard" },
    { src: un, alt: "United Nations" },
    { src: woodland, alt: "Woodland Trust" },
  ];

  const CertificationsComponent = () => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex((current) => (current + 1) % logos.length);
      }, 2000); // Change logo every 3 seconds
  
      return () => clearInterval(interval);
    }, []);
  
    return (
      <div className="certifications-section">
        {/* ... your text here ... */}
        <div className="logo-bar">
          {logos.map((logo, index) => (
            <div
              key={index}
              className={`logo-bar__item ${index === activeIndex ? 'active' : ''}`}
            >
              <img src={logo.src} alt={logo.alt} className="certification-logo" />
            </div>
          ))}
        </div>
        {/* ... your contact info here ... */}
      </div>
    );
  };
  
  export default CertificationsComponent;