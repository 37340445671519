import React from 'react';
import './ServicesComponent.css'; 


const ServicesComponent = () => {
  return (
    <div className="services-section">
      <div className="services-text">
        <h2>Who we are</h2>
        <p>
        We  are  a  leading  sustainability  consultancy firm dedicated  to  driving 
        environmental  excellence  and  empowering  organizations  worldwide  to 
        achieve  their  sustainability  goals.  We  are  based  in  London  (UK)  and  help clients 
        regardless of their size or the difficulties of the constraints we're facing. 
<br /><br /><br />
        We are a composite team of seasoned professionals, with deep experiences and vast networks in ecological sciences, industrial engineering, business operations
        and in quantitative finance. We enjoy tackling difficult challenges and transforming obstacles into opportunities.
         </p>
        </div>
    </div>
  );
};

export default ServicesComponent;