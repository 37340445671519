import React from 'react';
import './HeaderComponent.css'; // Make sure to create a corresponding CSS file for styling
import logo from './assets/c13_logo.png';


const HeaderComponent = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <a href="/" className="logo">
          <img
            src={logo}
            alt="Carbon13 Logo"
          />
        </a>
      </nav>
    </header>
  );
};

export default HeaderComponent;