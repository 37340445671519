import React from 'react';
import './DeveloperServicesComponent.css';
import certifIcon from './assets/agreement.png'; // Assume this is the path to your icon
import tradeIcon from './assets/stock.png';
import appraisalIcon from './assets/clipboard.png';
import implIcon from './assets/manufacturing.png';

const DeveloperServicesComponent = () => {
  return (
    <div className="business-services">
      <h2>For project developers</h2>
         <p>  We understand the stakes are high, both for our clients and for the planet, and thus have a strong commitment  to  integrity,  innovation,  and  collaboration,  we  provide 
expert guidance, tailored solutions, industrial implementation and actionable insights to help our clients 
navigate the complexities of sustainability and make a positive impact on the 
planet whilst maximising their revenue. </p>
<div className="service-row">
        <div className="content">
        <img src={appraisalIcon} alt="Appraisal" />
          <div className="title"><strong>Appraisal</strong></div>
          <p className="description">
            We evaluate your project and design a strategy to maximise its' impact. We provide quantifiable and measurable objectives and revenue goals for your project, assessing your specific constraints, needs and situation. We benchmark the potential of your project against trusted international standards  
          </p></div>
      </div>
      <div className="service-row">
        <div className="content">
        <img src={implIcon} alt="Implementation" />
          <div className="title"><strong>Implementation</strong></div>
          <p className="description">
            We handle project implementation details from start to finish, design and implement any needed industrial processes. We use our engineering expertise and our vast network of experts to deliver a solution tailored to your project goals. Our goal is to maximise both your positive ecological impact and revenue. 
          </p></div>
      </div>
      <div className="service-row">
        <div className="content">
        <img src={certifIcon} alt="Certification" />
          <div className="title"><strong>Certification</strong></div>
          <p className="description">
            We pick the best sustainability standard for your project and manage the certification process from start to finish, from the first draft of the project design document to credit issuance. We monitor the project and help you stay compliant throughout the entire certification period.  
          </p></div>
      </div>
      <div className="service-row">
        <div className="content">
        <img src={tradeIcon} alt="CreditTrading" />
          <div className="title"><strong>Credit trading</strong></div>
          <p className="description">
            We take care of trading your carbon credits for you ! We use our quantitative trading experience coupled to the best market intelligence tools for the sector to trade your credits at the best possible prices, and advertise your credits on every major market. 
          </p>        </div>
      </div>
    </div>
  );
};

export default DeveloperServicesComponent;
