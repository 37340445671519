import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactFormComponent.css'; // Ensure you have this CSS file

const ContactFormComponent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false); // State to manage snackbar visibility

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_a2riy4n';
    const templateId = 'template_ste383j';
    const pubKey = 'wNERj7wcFVZl8FpUM';

    const templateParams = {
      user_name: name,
      user_email: email,
      message: message,
    };

    emailjs.send(serviceId, templateId, templateParams, pubKey)
      .then(response => {
        console.log('SUCCESS!', response.status, response.text);
        setShowSnackbar(true); // Show success snackbar
        setTimeout(() => setShowSnackbar(false), 3000); // Hide snackbar after 3 seconds
      }, err => {
        console.log('FAILED...', err);
      });

    // Clear form fields
    setName('');
    setEmail('');
    setMessage('');
  };


  return (
    <div className='formSection'>
      <h2>For enquiries, use the contact form below ! </h2>
    <form className="contact-form" onSubmit={handleSubmit}>
      <label htmlFor="name">Company name :</label>
      <input
        type="text"
        id="user_name"
        name="user_name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />

      <label htmlFor="email">Corporate e-mail :</label>
      <input
        type="email"
        id="user_email"
        name="user_email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      <label htmlFor="message">Your Message :</label>
      <textarea
        id="message"
        name="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <button type="submit">Send</button>
    </form>
    {showSnackbar ? (
        <div className="snackbar">Message sent successfully!</div>
      ) : null}
    </div>
  );
};

export default ContactFormComponent;
